import React, { useEffect } from "react";
import AntTable from "../../ui/table/Table";
import { useSelector, useDispatch } from "react-redux";
import { getMatching } from "../../../actions/applicationActions";
import axios from "axios";
import { Button } from "antd";
import * as XLSX from "xlsx";

function Matching() {
  const dispatch = useDispatch();

  const matchedStudents =
    useSelector((state) => state.application.matching) || [];

  const ButtonStyle = {
    backgroundColor: "#64A0C8",
    color: "white",
    borderRadius: "4px",
  };

  const exportToExcel = (data) => {
    // Prepare data for Excel by flattening the structure
    const excelData = data.map((entry) => {
      return {
        "Company Name": entry.companyName,
        "Number of Matched Students": entry.studentCount,
        "First Name": entry.firstname,
        "Last Name": entry.lastname,
        "Matriculation Number": entry.matrikelnummer,
        Email: entry.email,
        "Place of Birth": entry.placeofbirth,
        "Current Degree": entry.currentdegree,
        Gender: entry.gender,
        "Country of Birth": entry.countryofbirth,
        "Matched Field of Study": entry.currentfieldofstudy,
        Semester: entry.semester,
        "Registered Before": entry.registeredBefore,
        "Participated Before": entry.previousParticipation,
        "German Language Level": entry.germanlanguagelevel,
        "Registered In": entry.registeredIn,
        Motivation: entry.motivation,
      };
    });
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Matched Students and Companies");
    XLSX.writeFile(wb, "Matched-Students-Companies.xlsx");
  };

  let studentCount = -1;
  // Function to flatten the data: associate each student with their respective company
  const flattenData = (data) => {
    const flattened = [];

    console.log("data:: " + JSON.stringify(data));
    data.forEach((company) => {
      const companyName = company.companyName;
      studentCount = company.students.length;

      // Loop through the students of each company and add them to the table data
      company.students.forEach((student, index) => {
        flattened.push({
          companyName,
          studentCount,
          firstname: student.firstname,
          lastname: student.lastname,
          matrikelnummer: student.matrikelnummer,
          email: student.email,
          placeofbirth: student.placeofbirth,
          currentdegree: student.currentdegree,
          gender: student.gender,
          countryofbirth: student.countryofbirth,
          currentfieldofstudy: student.currentfieldofstudy,
          semester: student.semester,
          motivation: student.motivation,
          registeredBefore: student.registeredBefore,
          previousParticipation: student.previousParticipation,
          germanlanguagelevel: student.germanlanguagelevel,
          registeredIn: student.registeredIn,
        });
      });
    });

    return flattened;
  };

  const columns = [
    {
      dataField: "companyName",
      text: "Company Name",
    },
    {
      dataField: "studentCount",
      text: "Number of Matched Students",
    },
    {
      dataField: "firstname",
      text: "First Name",
    },
    {
      dataField: "lastname",
      text: "Last Name",
    },
    {
      dataField: "matrikelnummer",
      text: "Matriculation Number",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "placeofbirth",
      text: "Place of Birth",
    },
    {
      dataField: "currentdegree",
      text: "Current Degree",
    },
    {
      dataField: "gender",
      text: "Gender",
    },
    {
      dataField: "countryofbirth",
      text: "Country of Birth",
    },
    {
      dataField: "currentfieldofstudy",
      text: "Matched Field of Study",
    },
    {
      dataField: "semester",
      text: "Semester",
    },

    {
      dataField: "registeredBefore",
      text: "Registered Before",
    },
    {
      dataField: "previousParticipation",
      text: "Participated Before",
    },
    {
      dataField: "germanlanguagelevel",
      text: "German Language Level",
    },
    {
      dataField: "registeredIn",
      text: "Registered In",
    },
    {
      dataField: "motivation",
      text: "Motivation",
    },
  ];

  const exportMatchingInformation = () => {
    axios({
      url: `/api/forms/matching`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Matched-Students-Companies.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    dispatch(getMatching());
  }, [dispatch]);

  const tableData = flattenData(matchedStudents); // Flatten the data before displaying it

  return (
    <div className="d-flex flex-column align-items-center p-5">
      <Button
        type="primary"
        style={ButtonStyle}
        onClick={() => exportToExcel(tableData)}
      >
        Export Matched Students and Companies Information in Excel
      </Button>
      <AntTable data={tableData} columns={columns} />
    </div>
  );
}

export default Matching;
