import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import axios from "axios";

const AdminProfileActions = () => {
  //call /api/matching
  // axios({
  //   url: `/api/company/downloadcompanies`,
  //   method: "GET",
  //   responseType: "blob",
  // }).then((response) => {
  //   const url = window.URL.createObjectURL(new Blob([response.data]));
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", `Registered-Companies.xlsx`);
  //   document.body.appendChild(link);
  //   link.click();
  // });

  return (
    <div className="mb-4">
      <h6>Student Information:</h6>
      <div className="dash-buttons">
        <Link to="/view-student-profiles">
          <Button
            type="default"
            icon={<UserOutlined />}
            className="ant-btn-light"
          >
            View Student profiles
          </Button>
        </Link>
      </div>
      <h6 className="mt-4">Company Information:</h6>
      <div className="dash-buttons">
        <Link to="/view-company-profiles">
          <Button
            type="default"
            icon={<UserOutlined />}
            className="ant-btn-light"
          >
            View Company Info
          </Button>
        </Link>
      </div>
      <h6 className="mt-4">Matching Students to Companies:</h6>
      <div className="dash-buttons">
        <Link to="/matching">
          <Button
            type="default"
            icon={<UserOutlined />}
            className="ant-btn-light"
          >
            Matching
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default AdminProfileActions;
